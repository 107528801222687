import requester from './requester'
import parseReaderUrl from './parseReaderUrl'
import getUTMParamsFromUrl from './getUTMParamsFromUrl'
import getQueryParamsFromUrl from './getQueryParamsFromUrl'
import getUTMParamsFromQuery from './getUTMParamsFromQuery'
import getQueryStringFromUrl from './getQueryStringFromUrl'
import safeJSONParse from './safeJSONParse'
import { FontLinks, getFontFacesStr } from './getFonts'
import getUuid from './uuid'
import redirect from './redirect'
import serverSideUtils from './serverSide'
import iframeUtils from './iframeUtils'
import isoCodeAndLocale from './isoCodeAndLocale'
import {
  getGeoIpData,
  getLocaleByIsoCode,
  isValidLocaleOrDefault,
} from './network'

export default {
  requester,
  redirect,
  getUuid,
  FontLinks,
  getFontFacesStr,
  parseReaderUrl,
  getUTMParamsFromUrl,
  getQueryParamsFromUrl,
  getUTMParamsFromQuery,
  getQueryStringFromUrl,
  iframeUtils,
  safeJSONParse,
  getGeoIpData,
  getLocaleByIsoCode,
  isValidLocaleOrDefault,
  serverSideUtils,
  isoCodeAndLocale,
}
