const getUTMParamsFromQuery = (query) => {
  return {
    utm_source: query?.utm_source,
    utm_medium: query?.utm_medium,
    utm_campaign: query?.utm_campaign,
    utm_content: query?.utm_content,
    utm_term: query?.utm_term,
  }
}
export default getUTMParamsFromQuery
