import getQueryStringFromUrl from './getQueryStringFromUrl'

function redirect(params) {
  const { ctx, location, status = 302 } = params
  const { asPath, resolvedUrl } = ctx
  let paramsString = getQueryStringFromUrl(asPath || resolvedUrl)
  if (ctx.res) {
    ctx.res.writeHead(status, {
      Location: `${location}${paramsString}`,
    })
    ctx.res.end()
  }
}

export default redirect
