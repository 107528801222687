import requestIp from 'request-ip'
import API from '../repository/readerAPI'

function getPublicIP(req) {
  return process.env.NODE_ENV === 'development'
    ? 'localhost'
    : requestIp.getClientIp(req) || '0.0.0.0'
}

const getGeoIpData = async (req) => {
  const domain = process.browser ? 'UI' : ''
  const ip = getPublicIP(req)
  const response = await API.getGeoIP(domain)({
    params: { ip: encodeURIComponent(ip) },
  })

  const geoip = response.data

  return {
    ip,
    isoCode: geoip.isoCode || null,
  }
}

const _getLocaleByIsoCode = (campaign, code) =>
  campaign.localisation &&
  campaign.localisation.find(
    (l) => l.countryCodes && l.countryCodes.find((c) => c === code),
  )?.locale

const _getDefaultLocale = (campaign) => _getLocaleByIsoCode(campaign, '*')

const getLocaleByIsoCode = ({ campaign, isoCode }) =>
  _getLocaleByIsoCode(campaign, isoCode) || _getDefaultLocale(campaign) || 'en'

const isValidLocaleOrDefault = ({ campaign, locale }) => {
  const isValidLocale = (_locale) =>
    campaign.localisation &&
    campaign.localisation.find((l) => l.locale === _locale)?.locale

  return isValidLocale(locale) || _getDefaultLocale(campaign) || 'en'
}

export { getPublicIP, getGeoIpData, getLocaleByIsoCode, isValidLocaleOrDefault }
