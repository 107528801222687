import getConfig from 'next/config'

const { serverRuntimeConfig, publicRuntimeConfig } = getConfig()

export const config = {
  ...publicRuntimeConfig,
  ...serverRuntimeConfig,
}

export default config
