export default {
  root: {
    fontFamily: 'regular',
    fontSize: '14px',
    color: 'primary',
    backgroundColor: '#FFF',
    a: {
      textDecoration: 'none',
    },
  },
}
