import axios from 'axios'
import getConfig from 'next/config'
import removeEmpty from '../utils/removeEmpty'
const { publicRuntimeConfig } = getConfig()

const APIConfig = publicRuntimeConfig.APIConfig

const API = (apiName) => (domain) => {
  const findConfigByName = (config) => config.name === apiName

  const service = APIConfig.find(findConfigByName)

  if (!service) {
    throw new Error(`This ${apiName} does not exist on the API configuration`)
  }

  const domainEndpoint = {
    UI: '/api',
    default: service.baseUrl + service.endpoint,
  }

  const endpoint = domainEndpoint[domain] || domainEndpoint['default']

  const get = (path, params = {}) => {
    const options = {
      headers: removeEmpty({
        Authorization:
          params?.headers?.Authorization || service.defaultAuthorization,
        ...params.headers,
      }),
      params: params.query || params.params || {},
    }
    return axios.get(endpoint + path, options).catch((error) => {
      throw error
    })
  }

  const post = (path, params = {}) => {
    const { headers = {}, body, query = {} } = params

    const options = {
      headers: removeEmpty({
        Authorization: headers.Authorization || service.defaultAuthorization,
        ...headers,
      }),
      params: query || params.params || {},
    }
    return axios.post(endpoint + path, body, options).catch((error) => {
      throw error
    })
  }

  const put = (path, params) => {
    const { body, headers = {} } = params

    const options = {
      ...headers,
    }

    return axios.put(endpoint + path, body, options).catch((error) => {
      console.log(`Unable to PUT: ${error.message}`)
      throw error
    })
  }

  return {
    get,
    post,
    put,
  }
}

export default API
