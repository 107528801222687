import React from 'react'
import { Global, css } from '@emotion/react'
import utils from '../utils'

const styles = css`
  ${utils.getFontFacesStr}
  html {
    scroll-behavior: smooth;
  }
`

const GlobalComponent = () => <Global styles={styles} />

export default GlobalComponent
