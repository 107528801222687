import { ReaderAPI } from '../../repository'

const getAccountByCustomUrl = async (customUrl) => {
  const accounts = await ReaderAPI.getAccounts({
    domain: '',
    options: {
      params: {
        customUrl,
        size: 1,
        isEnabled: true,
      },
    },
  })
  return accounts?.data[0]
}

export default getAccountByCustomUrl
