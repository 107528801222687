import { ReaderAPI } from '../../repository'

const getCampaign = async (campaignSlug, subdomain) => {
  const campaign = subdomain
    ? await ReaderAPI.getAccountCampaignBySlug({
        domain: '',
        body: {
          subdomain,
          slug: campaignSlug,
        },
      })
    : await ReaderAPI.getCampaignBySlug({
        domain: '',
        campaignSlug,
      })
  return campaign?.data
}

export default getCampaign
