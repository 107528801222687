import * as Infra from '../infra'
export default {
  getPage,
  getPageById,
  getCampaign,
  getTheme,
  getPageBySlug,
  getCampaignBySlug,
  getAccountCampaignBySlug,
  getAccounts,
  sendSubmitForm,
  trackFacebookConversion,
  trackEvent,
  uploadFile,
  getGeoIP,
}

function getPage({ domain, pageId }) {
  return Infra.API('CAMPAIGN_API')(domain).get(`/page/${pageId}/display`)
}

function getPageById({ domain, pageId }) {
  return Infra.API('CAMPAIGN_API')(domain).get(`/page/${pageId}`)
}

function getPageBySlug({ domain, campaignId, pageSlug, locale = 'en' }) {
  return Infra.API('CAMPAIGN_API')(
    domain,
  ).get(`/page/${campaignId}/${pageSlug}/display`, { params: { locale } })
}

function getCampaign({ domain, campaignName }) {
  return Infra.API('CAMPAIGN_API')(domain).get(`/campaign/${campaignName}`)
}

function getCampaignBySlug({ domain, campaignSlug }) {
  return Infra.API('CAMPAIGN_API')(domain).get(
    `/campaign/${campaignSlug}/display`,
  )
}

function getAccounts({ domain, options = {} }) {
  const params = new URLSearchParams()
  if (options.params.subdomain) {
    params.append('subdomain', options.params.subdomain)
  }
  if (options.params.customUrl) {
    params.append('customUrl', options.params.customUrl)
  }
  if (options.params.isEnabled) {
    params.append('isEnabled', options.params.isEnabled)
  }
  if (options.params.page) {
    params.append('page', options.params.page)
  }
  if (options.params.size) {
    params.append('size', options.params.size)
  }
  return Infra.API('CAMPAIGN_API')(domain).get(`/account`, {
    ...options,
    params,
  })
}

function getAccountCampaignBySlug({ domain, body: { subdomain, slug } }) {
  return Infra.API('CAMPAIGN_API')(domain).get(
    `/account/${subdomain}/campaign/${slug}/display`,
  )
}

function getTheme({ domain, campaignId }) {
  return Infra.API('CAMPAIGN_API')(domain).get(
    `/campaign/${campaignId}/theming`,
  )
}

function sendSubmitForm(domain = 'UI') {
  return ({ campaignId, ...params }) =>
    Infra.API('CAMPAIGN_API')(domain).post(
      `/campaign/${campaignId}/submit`,
      params,
    )
}

function trackFacebookConversion(domain = 'UI') {
  return ({ ...params }) =>
    Infra.API('CAMPAIGN_API')(domain).post(`/analytics/facebook`, params)
}

function trackEvent(domain = 'UI') {
  return ({ campaignId, event_name, data }) =>
    Infra.API('CAMPAIGN_API')(domain).post(`/campaign/${campaignId}/event`, {
      body: {
        event_name,
        data,
      },
    })
}

function uploadFile(domain = 'UI') {
  return ({ body, campaignId, email, fileType }) =>
    Infra.API('CAMPAIGN_API')(domain).post(`/campaign/${campaignId}/file`, {
      body,
      query: {
        email,
        fileType,
      },
    })
}

function getGeoIP(domain = 'UI') {
  return (params) => Infra.API('GEO_IP_API')(domain).get('/country', params)
}
