import getConfig from 'next/config'

const { publicRuntimeConfig } = getConfig()

export default parseReaderUrl

const getDomain = (hostname) =>
  publicRuntimeConfig.DOMAINS.find((domain) => hostname.match(domain))

const getSubdomain = (hostname, domain) => {
  const split = hostname.match(`.${domain}`) && hostname.split(`.${domain}`)
  return split && split.length > 0 ? split[0] : null
}

const isLocalhost = (hostname) => {
  const splitHostname = hostname && hostname.split(':')
  return splitHostname?.length > 0 && splitHostname[0] === 'localhost'
}

function parseReaderUrl(ctx) {
  const { campaign: campaignSlug, locale: urlLocale, page: pageSlug } =
    ctx?.query || {}
  const hostname = ctx?.req?.headers?.host
  const domain = hostname && getDomain(hostname)
  const accountSubdomain = domain ? getSubdomain(hostname, domain) : null

  return {
    customUrl: domain || isLocalhost(hostname) ? null : hostname,
    accountSubdomain,
    campaignSlug,
    pageSlug,
    urlLocale,
  }
}
