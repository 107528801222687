import utils from '../index'

const redirectToCampaign = async (ctx, campaign) => {
  const geoIpData = await utils.getGeoIpData(ctx.req)
  const locale = utils.getLocaleByIsoCode({
    campaign: campaign,
    isoCode: geoIpData?.isoCode,
  })
  utils.serverSideUtils.redirectToPage({
    ctx,
    campaignSlug: campaign.slug,
    locale,
    pageSlug: 'landing',
  })
}

export default redirectToCampaign
