import React, { useEffect } from 'react'
import { useRouter } from 'next/router'
import { ThemeProvider, merge } from 'theme-ui'
import Head from 'next/head'
import { FormTheme } from '@guidesmiths/react-form-builder'

import { AppProviders } from '../contexts'
import { theme } from '../styles'
import GlobalComponent from '../styles/global'
import initServiceWorker from '../mocks'
import { FontLinks } from '../utils/getFonts'
import 'react-datepicker/dist/react-datepicker.css'
import { ReaderAPI } from '../repository'

initServiceWorker()

const existingTheme = { ...FormTheme, ...theme }
function CampaignReaderApp({ Component, pageProps, ...initialProps }) {
  const { asPath, query } = useRouter()
  const { campaign } = pageProps
  const locale = query?.locale

  useEffect(() => {
    if (campaign?.facebookConversion?.enabled) {
      ReaderAPI.trackFacebookConversion()({
        body: {
          eventName: 'PageView',
          userData: '',
          campaignSlug: campaign.slug,
        },
      })
    }
  }, [asPath])

  const finalTheme = (locale) => {
    let uploadedTheme = pageProps.uploadedTheme || {}

    let fonts = pageProps.uploadedTheme && pageProps.uploadedTheme?.fonts
    fonts =
      fonts &&
      (fonts[locale] ?? { body: fonts['body'], heading: fonts['heading'] })

    let styles = pageProps.uploadedTheme && pageProps.uploadedTheme?.styles
    let localeToggle = styles && pageProps.uploadedTheme?.styles.localeToggle
    localeToggle = localeToggle && (localeToggle[locale] ?? localeToggle)
    styles = { ...styles, localeToggle }

    let forms = pageProps.uploadedTheme && pageProps.uploadedTheme?.forms
    forms = forms && (forms[locale] ?? forms)

    uploadedTheme = { ...uploadedTheme, fonts, styles, forms }
    const theme = merge(existingTheme, uploadedTheme)

    return theme
  }

  return (
    <>
      <Head>
        <title>{pageProps?.campaign?.name}</title>
        <link
          rel="shortcut icon"
          href={pageProps?.campaign?.meta?.favicon?.faviconUrl}
        />
        <FontLinks />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0"
        />
      </Head>
      <AppProviders>
        <ThemeProvider theme={finalTheme(locale)}>
          <GlobalComponent />
          <Component {...pageProps} {...initialProps} />
        </ThemeProvider>
      </AppProviders>
    </>
  )
}

export default CampaignReaderApp
