import getConfig from 'next/config'

export default initServiceWorker

function initServiceWorker() {
  const { publicRuntimeConfig } = getConfig()

  if (!publicRuntimeConfig.IS_MOCKING_ENABLED) return null

  if (typeof window === 'undefined') {
    console.info('server active')
    const { server } = require('./server')
    server.listen()
  } else {
    console.info('worker active')
    const { worker } = require('./browser')
    worker.start()
  }
}
