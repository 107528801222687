import getCampaign from './getCampaign'
import isCampaignClosed from './isCampaignClosed'
import getAccountByCustomUrl from './getAccountByCustomUrl'
import redirectToPage from './redirectToPage'
import redirectToCampaign from './redirectToCampaign'
import isNotSupportedBrowser from './isNotSupportedBrowser'

export default {
  getCampaign,
  isCampaignClosed,
  getAccountByCustomUrl,
  redirectToPage,
  redirectToCampaign,
  isNotSupportedBrowser,
}
