import utils from '../index'

const redirectToPage = ({ ctx, campaignSlug, locale, pageSlug }) => {
  utils.redirect({
    location: `/${campaignSlug}/${locale}/${pageSlug}`,
    ctx,
  })
}

export default redirectToPage
