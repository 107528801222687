const sendIframeMessage = (message) => window.parentIFrame.sendMessage(message)

const isIframe = () => 'parentIFrame' in window

const iframeRedirection = (redirectUrl) =>
  sendIframeMessage(
    {
      redirectUrl,
    },
    '*',
  )

export default { isIframe, iframeRedirection }
