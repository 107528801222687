const isNotSupportedBrowser = (ctx) => {
  const userAgent = ctx?.req
    ? ctx.req.headers['user-agent']
    : navigator.userAgent

  return (
    userAgent.indexOf('Trident/') !== -1 || userAgent.indexOf('MSIE') !== -1
  )
}

export default isNotSupportedBrowser
